//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from 'moment'
export default {
    name: 'ShareSeriesMonitor',
    components: { DatePicker },
    data: function () {
        return {
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: 2,
            },
            Filter: {},
            shareInfo: {},
            separator: '',
            shareInfoDate: '',
            shareInfoDateFormated: '',
            fromDate: '',
            toDate: '',
            chartType: 'line',
            otherCharts: 'volume',
            shareTradesList: [],
            sharePerformance: {},
            yearsList: [],
            performanceOptions: [],
            performanceOption: 1,
            disabledChart: false,
            tabName: ''
        };
    },
    methods: {
        Print: function () {

            var self = this;
            self.query.Language = self.$i18n.locale;

            self.axios.post(process.env.VUE_APP_APIEndPoint + 'ShareSeriesMonitor/PrintDailyTradesReport', self.query, self.headers)
                .then(function (res) {
                    var link = document.createElement('a');
                    link.href = "data:application/octet-stream;base64," + res.data;
                    link.target = '_blank';
                    link.download = self.$t('AllTradesDuringToday') + ".xlsx";
                    link.click();
                    link.remove();  //afterwards we remove the element again
                })
                .catch(function (err) {

                    return console.log(err);
                }).finally(function () {
                });
        },
        getYearsList: function () {
            let self = this;
            self.yearsList = [];
            var currentYear = new Date().getFullYear();
            self.yearsList.push(currentYear);
            for (var i = 0; i < 4; i++) {
                self.yearsList.push(--currentYear);
            }
        },
        getPerformanceOptions: function () {
            let self = this;
            self.performanceOptions.push({ key: 1, text: self.$t('SharePriceEvolution') });
            self.performanceOptions.push({ key: 2, text: self.$t('HistoricalDevelopmentOfTheSharePrice') });
        },
        financial: function (x) {
            if (!x)
                return 0;
            return Number.parseFloat(x).toFixed(2);
        },
        load: function () {
            let self = this;

            //self.separator = 'Year';
            self.loadDailyShareInfo();


        },
        loadDailyShareInfo: function () {
            let self = this;
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + 'ShareSeriesMonitor/GetDailyShareInfo')
                .then(res => {
                    if (res.data.IsSuccess)
                        self.shareInfo = res.data.Data;

                    self.shareInfo.CreatedDate = moment(new Date(self.shareInfo.CreatedDate)).locale("en").format('YYYY-MM-DD HH:mm');

                    if (self.shareInfo.ModifiedDate)
                        self.shareInfo.ModifiedDate = moment(new Date(self.shareInfo.ModifiedDate)).locale("en").format('YYYY-MM-DD HH:mm');

                    self.shareInfoDate = self.shareInfo.ModifiedDate ? self.shareInfo.ModifiedDate : self.shareInfo.CreatedDate;

                    self.shareInfoDateFormated = moment(self.shareInfoDate).locale(self.$i18n.locale == "ar" ? "ar-SA" : "en").format('LLL ZZ');
                    document.getElementById("defaultOpen").click();

                    self.separator = 'Year';
                    self.changePeriod(self.separator);
                    console.log(self.shareInfo);
                });
        },
        changeDate: function () {
            var self = this;
            if (self.fromDate && self.toDate)
                self.loadShareChartData();
        },
        loadShareChartData: function () {
            let self = this;

            self.query.Filter = [];

            if (self.fromDate && self.fromDate != "") {
                self.query.Filter.push({
                    FieldName: "FromDate",
                    Operation: "=",
                    value: moment(self.fromDate).locale("en").format('YYYY-MM-DD HH:mm'),
                });
            }
            if (self.toDate && self.toDate != "") {
                self.query.Filter.push({
                    FieldName: "ToDate",
                    Operation: "=",
                    value: moment(self.toDate).locale("en").format('YYYY-MM-DD HH:mm'),
                });
            }

            if (self.separator && self.separator != "") {
                self.query.Filter.push({
                    FieldName: "FilterKey",
                    Operation: "=",
                    value: self.separator
                });
            }

            self.drawSharesChart();
        },
        drawSharesChart: function () {
            let self = this;
            self.axios
                .post(process.env.VUE_APP_APIEndPoint + 'ShareSeriesMonitor/GetShareChartData', self.query)
                .then(response => {
                    if (response.data.IsSuccess) {

                        let data = response.data.Data;

                        // split the data set into ohlc and volume
                        var ohlc = [],
                            volume = [],
                            changePercentage = [],
                            dataLength = data.length,
                            i = 0;

                        for (i; i < dataLength; i += 1) {

                            switch (self.separator) {
                                case "Day":
                                case "5Days":
                                    ohlc.push([
                                        data[i]['data'][0], // the date
                                        data[i]['data'][1], // lastTradePrice
                                    ]);
                                    break;
                                case "3Months":
                                case "6Months":
                                case "Year":
                                case "3Year":
                                case "LastPeriod":
                                    ohlc.push([
                                        data[i]['data'][0], // the date
                                        data[i]['data'][1], // lastTradePrice
                                        data[i]['data'][2], // open
                                        data[i]['data'][3], // highPrice
                                        data[i]['data'][4], // lowPrice
                                        data[i]['data'][5] // closePrice
                                    ]);
                                default:
                                    ohlc.push([
                                        data[i]['data'][0], // the date
                                        data[i]['data'][1], // lastTradePrice
                                        data[i]['data'][2], // open
                                        data[i]['data'][3], // highPrice
                                        data[i]['data'][4], // lowPrice
                                        data[i]['data'][5] // closePrice
                                    ]);
                            }

                            if (self.otherCharts == 'volume') {
                                volume.push([
                                    data[i]['data'][0], // the date
                                    data[i]['data'][6] // the volumeTraded
                                ]);
                            }

                            if (self.otherCharts == 'changePercentage') {
                                changePercentage.push([
                                    data[i]['data'][0], // the date
                                    data[i]['data'][7] // the changePercentage
                                ]);
                            }
                        }


                        Highcharts.stockChart('container', {
                            chart: {
                                zoomType: 'xy'
                            },
                            rangeSelector: {
                                enabled: false
                            },
                            stockTools: {
                                gui: {
                                    enabled: false // disable the built-in toolbar
                                }
                            },
                            xAxis: {
                                gridLineWidth: 1,
                            },
                            yAxis: [{
                                opposite: false,
                                labels: {
                                    align: 'right',
                                    x: -3
                                },
                                title: {
                                    text: self.$t('SAR')
                                },
                                height: '60%',
                                lineWidth: 1,
                                shadow: true,
                                resize: {
                                    enabled: true
                                }
                            }, {
                                opposite: false,


                                labels: {
                                    align: 'right',
                                    x: -3
                                },
                                top: '65%',
                                height: '35%',
                                offset: 0,
                                lineWidth: 1,
                                shadow: true,
                            }],
                            tooltip: {
                                shape: 'square',
                                headerShape: 'callout',
                                borderWidth: 1,
                                shadow: true,
                                positioner: function (width, height, point) {
                                    if (point.series.name == 'Sasco') {
                                        if (self.$i18n.locale == "ar")
                                            if ((self.separator == 'Day' || self.separator == '5Days') && (self.chartType == 'line' || self.chartType == 'area'))
                                                return { x: 1145, y: 0 };
                                            else
                                                return { x: 920, y: 0 };
                                        else
                                            return { x: 55, y: 0 };
                                    }
                                    else if (point.series.name == 'Volume') {
                                        if (self.$i18n.locale == "ar")
                                            return { x: 1145, y: 155 };
                                        else
                                            return { x: 55, y: 155 };

                                    }
                                    else if (point.series.name == 'ChangePercentage') {
                                        if (self.$i18n.locale == "ar")
                                            return { x: 1145, y: 155 };
                                        else
                                            return { x: 55, y: 155 };

                                    }
                                },
                                useHTML: true,
                                formatter: function () {
                                    let s = '';
                                    this.points.forEach(point => {

                                        if (point.series.name == 'Sasco') {
                                            if ((self.separator == 'Day' || self.separator == '5Days') && (self.chartType == 'line' || self.chartType == 'area'))
                                                s += '<b><span style = "color:' + point.series.color + ';">' + self.$t('SASCO') + ' </b>'
                                                    + '<b><span style = "color:' + point.series.color + ';">' + self.$t('Last') + ': ' + point.y.toFixed(2) + '</b></br></br>';
                                            else {
                                                s += '<b><span style = "color:' + point.series.color + ';">' + self.$t('SASCO') + ' '
                                                    + '<b><span style = "color:' + point.series.color + ';">' + self.$t('Open') + ': ' + point.point.open.toFixed(2) + ' |</b>'
                                                    + '<b><span style = "color:' + point.series.color + ';">' + self.$t('High') + ': ' + point.point.high.toFixed(2) + ' |</b>'
                                                    + '<b><span style = "color:' + point.series.color + ';">' + self.$t('Low') + ': ' + point.point.low.toFixed(2) + ' |</b>'
                                                    + '<b><span style = "color:' + point.series.color + ';">' + self.$t('CloseP') + ': ' + point.point.close.toFixed(2) + '</b></br></br>';
                                            }

                                        }
                                        else if (point.series.name == 'Volume') {
                                            s += '<b><span style = "color:' + point.series.color + ';">' + self.$t('Volume') + ': ' + point.y.toFixed(2) + '</b></br></br>';
                                        }
                                        else if (point.series.name == 'ChangePercentage') {
                                            s += '<b><span style = "color:' + point.series.color + ';">' + self.$t('changePercentage') + ': ' + point.y.toFixed(2) + '</b>';
                                        }

                                    });

                                    return s;
                                }
                            },
                            series: [{
                                type: self.chartType,
                                id: 'sasco',
                                useOhlcData: self.separator == 'Day' || self.separator == '5Days' ? false : true,
                                name: 'Sasco',
                                data: ohlc,
                            }

                                , {
                                type: 'column',
                                id: 'volume',
                                name: 'Volume',
                                data: volume,
                                yAxis: 1
                            }
                                , {
                                type: 'line',
                                id: 'changePercentage',
                                name: 'ChangePercentage',
                                data: changePercentage,
                                yAxis: 1
                            }],
                            responsive: {
                                rules: [{
                                    condition: {
                                        maxWidth: 800
                                    },
                                    chartOptions: {
                                        rangeSelector: {
                                            inputEnabled: false
                                        }
                                    }
                                }]
                            }
                        });
                    }
                });


        },
        getShareData: function () {

        },
        getTrades: function () {
            let self = this;
            var fromDate = new Date();
            fromDate.setHours(10);
            fromDate.setMinutes(0);
            if (new Date() < fromDate)
                fromDate = moment(fromDate).subtract(1, "days");

            self.query.Filter = [];

            if (fromDate && fromDate != "") {
                self.query.Filter.push({
                    FieldName: "FromDate",
                    Operation: "=",
                    value: moment(fromDate).locale("en").format('YYYY-MM-DD HH:mm'),
                });
            }
            if (self.shareInfoDate && self.shareInfoDate != "") {
                self.query.Filter.push({
                    FieldName: "ToDate",
                    Operation: "=",
                    value: moment(self.shareInfoDate).locale("en").format('YYYY-MM-DD HH:mm'),
                });
            }

            self.axios
                .post(process.env.VUE_APP_APIEndPoint + 'ShareSeriesMonitor/GetDailyTradesData', self.query)
                .then(response => {
                    if (response.data.IsSuccess) { }

                    console.log(response.data.Data);

                    self.shareTradesList = [...response.data.Data];

                    self.shareTradesList.map(x =>
                        x.CreatedDate = moment(x.CreatedDate).locale("en").format('YYYY-MM-DD HH:mm:ss')
                    );

                    self.shareTradesList = self.shareTradesList.slice(-10);
                });
        },
        getPerformance: function () {
            let self = this;
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + 'ShareSeriesMonitor/GetSharePerformance')
                .then(res => {
                    if (res.data.IsSuccess)
                        self.sharePerformance = res.data.Data;

                    self.getPerformanceOptions();
                    self.getYearsList();
                });
        },
        getDatesInRange: function (startDate, endDate) {
            const date = new Date(startDate.getTime());

            const dates = [];

            while (date <= endDate) {
                dates.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }

            return dates;
        }
        ,
        changePeriod: function (separator) {
            let self = this;
            self.disabledChart = false;
            var from = new Date();
            from.setHours(10);
            from.setMinutes(0);

            if (new Date() < from)
                from = moment(from).subtract(1, "days");

            switch (separator) {
                case "Day":
                    self.fromDate = moment(from).locale("en").format('YYYY-MM-DD HH:mm');
                    self.toDate = self.shareInfoDate;
                    self.disabledChart = true;
                    self.chartType = 'line';
                    break;
                case "5Days":
                    self.fromDate = moment(from).subtract(4, "days").locale("en").format('YYYY-MM-DD HH:mm');
                    self.toDate = self.shareInfoDate;

                    var dates = self.getDatesInRange(new Date(self.fromDate), new Date(self.toDate));

                    var nationalDayDate = new Date((new Date()).getFullYear() , 11, 23);
                    var foundingDayDate = new Date((new Date()).getFullYear() , 1, 22);
                    var fridayNumber = 5;
                    var sturdayNumber = 6;
                    for (var i = 0; i < dates.length; i++) {
                        if (dates[i].getDay() == fridayNumber || dates[i].getDay() == sturdayNumber ||
                            new Date(dates[i].getFullYear(), dates[i].getMonth(), dates[i].getDate()).toLocaleDateString() == nationalDayDate.toLocaleDateString() ||
                            new Date(dates[i].getFullYear(), dates[i].getMonth(), dates[i].getDate()).toLocaleDateString() == foundingDayDate.toLocaleDateString())

                            self.fromDate = moment(self.fromDate).subtract(1, "days").locale("en").format('YYYY-MM-DD HH:mm');
                    }

                    self.disabledChart = true;
                    self.chartType = 'line';
                    break;
                case "3Months":
                    self.fromDate = moment(from).subtract(3, "months").locale("en").format('YYYY-MM-DD');
                    self.toDate = moment(self.shareInfoDate).subtract(1, "days").locale("en").format('YYYY-MM-DD');
                    break;
                case "6Months":
                    self.fromDate = moment(from).subtract(6, "months").locale("en").format('YYYY-MM-DD');
                    self.toDate = moment(self.shareInfoDate).subtract(1, "days").locale("en").format('YYYY-MM-DD');
                    break;
                case "Year":
                    self.fromDate = moment(from).subtract(1, "years").locale("en").format('YYYY-MM-DD');
                    self.toDate = moment(self.shareInfoDate).subtract(1, "days").locale("en").format('YYYY-MM-DD');
                    break;
                case "3Year":
                    self.fromDate = moment(from).subtract(3, "years").locale("en").format('YYYY-MM-DD');
                    self.toDate = moment(self.shareInfoDate).subtract(1, "days").locale("en").format('YYYY-MM-DD');
                    break;
                case "LastPeriod":
                    self.fromDate = moment(from).locale("en").format('YYYY-MM-DD HH:mm');
                    self.toDate = self.shareInfoDate;
                    break;
                default:
                    self.fromDate = moment(from).locale("en").format('YYYY-MM-DD HH:mm');
            }
            self.loadShareChartData();
        },
        openTab: function (evt, tabName) {
            var self = this;
            // Declare all variables
            var i, tabcontent, tablinks;

            // Get all elements with class="tabcontent" and hide them
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }

            // Get all elements with class="tablinks" and remove the class "active"
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }

            // Show the current tab, and add an "active" class to the button that opened the tab
            document.getElementById(tabName).style.display = "block";
            evt.currentTarget.className += " active";
            //$(evt.currentTarget).addClass("active").parent().find(".nav-link").removeClass(".active");
            self.tabName = tabName;
            switch (tabName) {
                //case "SharesChart":
                //    self.drawSharesChart();
                //    break;
                case "ShareData":
                    self.getShareData();
                    break;
                case "Trades":
                    self.getTrades();
                    break;
                case "Performance":
                    self.getPerformance();
                    break;
                default: ''
                //self.drawSharesChart();
            }
        },

        Scroll: function () {
            let self = this;
            self.query.PageNumber++;
            self.load();
        },

        handleScroll: function (event) {
            let infiniteList = document.getElementById("infinite-list");
            if (infiniteList) {
                let listPosition = infiniteList.offsetTop + infiniteList.offsetHeight;

                if (window.scrollY >= listPosition - 600) {
                    this.Scroll();
                }
            }
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    updated() {
        initGallerySlider();
    },
    mounted() {
        let self = this;
        self.load();




    },
    watch: {
        '$i18n.locale'() {
            let self = this;

            if (self.tabName = 'SharesChart')
                self.loadShareChartData()

        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
